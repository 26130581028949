.main-navigation {
  width: 100%;
  display: flex;
  flex-direction: column;

  .main-header--collapsed & {
    display: none;
    visibility: hidden;
  }

  @media (min-width: 800px) {
    width: auto;
    flex-direction: row;
    gap: 0;

    .main-header--collapsed & {
      display: flex;
      visibility: visible;
    }
  }

  @include desktop {
    gap: 8px;
  }
}

.main-navigation__link {
  display: flex;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  gap: 8px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;

  &--current {
    opacity: 0.4;
  }

  @media (min-width: 800px) {
    padding: 4px 16px;
    border-radius: 8px;

    &:hover {
      background-color: $base-secondary;
    }

    &--current {
      opacity: 1;
      background-color: $base-secondary;
    }

    &--login,
    &--account {
      display: none;
    }
  }

  @include desktop {
    padding: 4px 16px;
  }
}
