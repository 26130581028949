@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  line-height: inherit;
}

img {
  max-width: 100%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  width: 90vw;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}
.title--secondary {
  color: #e2b65c;
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 40px;
  min-height: 40px;
  max-width: max-content;
  text-decoration: none;
  border: none;
  background-color: #111111;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: 0.3s;
  cursor: pointer;
}
.button:disabled {
  opacity: 0.6;
  cursor: context-menu;
}
.button[data-loading] {
  opacity: 0.6;
  pointer-events: none;
  color: transparent;
  background-image: url("/images/spinner.gif");
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
}
.button--secondary {
  background-color: #e2b65c;
}
.button--gray {
  background-color: #B2B2B2;
}
@media (min-width: 1200px) {
  .button:hover {
    background-color: #e2b65c;
  }
}

.text {
  margin-top: 0;
  margin-bottom: 0;
  color: #000000;
  line-height: 24px;
  font-size: 16px;
}
.text--error {
  color: #DC0000;
}
.text--success {
  color: #66bb6a;
}

.box {
  margin-top: 8px;
}
.box p {
  margin: 0;
}
.box ul {
  margin: 0;
}

.page {
  font-family: "Roboto", sans-serif;
  height: 100%;
  font-size: 16px;
  line-height: 1.4;
  color: #111111;
}
.page::-webkit-scrollbar {
  width: 8px;
}
.page::-webkit-scrollbar-track {
  background-color: #e2b65c;
}
.page::-webkit-scrollbar-thumb {
  background-color: #111111;
}

.page__body {
  min-height: 100%;
  min-width: 320px;
  margin: 0;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  align-content: start;
  gap: 16px;
  background-color: #f4f4f4;
}
@media (min-width: 768px) {
  .page__body {
    gap: 24px;
  }
}

.main-logo {
  display: flex;
}

.main-navigation {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main-header--collapsed .main-navigation {
  display: none;
  visibility: hidden;
}
@media (min-width: 800px) {
  .main-navigation {
    width: auto;
    flex-direction: row;
    gap: 0;
  }
  .main-header--collapsed .main-navigation {
    display: flex;
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .main-navigation {
    gap: 8px;
  }
}

.main-navigation__link {
  display: flex;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  gap: 8px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}
.main-navigation__link--current {
  opacity: 0.4;
}
@media (min-width: 800px) {
  .main-navigation__link {
    padding: 4px 16px;
    border-radius: 8px;
  }
  .main-navigation__link:hover {
    background-color: #e2b65c;
  }
  .main-navigation__link--current {
    opacity: 1;
    background-color: #e2b65c;
  }
  .main-navigation__link--login, .main-navigation__link--account {
    display: none;
  }
}
@media (min-width: 1200px) {
  .main-navigation__link {
    padding: 4px 16px;
  }
}

.page-footer {
  background-color: #111111;
  color: #ffffff;
  padding: 16px 0;
}
@media (min-width: 768px) {
  .page-footer {
    padding: 24px 0;
  }
}
@media (min-width: 1200px) {
  .page-footer {
    padding: 32px 0;
  }
}

.page-footer__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.page-footer__container br {
  display: none;
}
@media (min-width: 1200px) {
  .page-footer__container {
    flex-direction: row;
    justify-content: space-between;
    gap: 32px;
  }
  .page-footer__container br {
    display: inline;
  }
}

.page-footer__links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: underline;
  align-items: center;
}
.page-footer__links a {
  cursor: pointer;
}
@media (min-width: 1200px) {
  .page-footer__links {
    align-items: flex-start;
  }
}

.page-footer__copyright {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 16px;
  color: #e2b65c;
  min-width: max-content;
}
@media (min-width: 1200px) {
  .page-footer__copyright::before {
    content: "";
    display: block;
    min-width: 58px;
    min-height: 45px;
    width: 58px;
    height: 45px;
    background-image: url('data:image/svg+xml,<svg width="58" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23a)"><rect y="6" width="52.102" height="40" rx="4" fill="%23E2B65C"/><path d="M8.33 35v-2.19l8.71-12.222H8.313v-3.043h13.363v2.19l-8.719 12.222h8.736V35H8.33Zm16.195-17.454h4.551l4.807 11.727h.204l4.807-11.727h4.551V35h-3.58V23.64h-.144l-4.517 11.275h-2.438L28.25 23.597h-.145V35h-3.58V17.546Z" fill="%23000"/></g><defs><clipPath id="a"><path fill="%23fff" d="M0 0h52v52H0z"/></clipPath></defs></svg>');
  }
}

.login-button {
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  gap: 8px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  display: none;
}
@media (min-width: 768px) {
  .login-button {
    display: flex;
    padding: 4px 24px;
    border-radius: 8px;
  }
  .login-button:hover {
    background-color: #e2b65c;
  }
}

.account-link {
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  gap: 8px;
  min-height: 40px;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  display: none;
}
@media (min-width: 768px) {
  .account-link {
    display: flex;
    padding: 4px 24px;
    border-radius: 8px;
  }
  .account-link:hover {
    background-color: #e2b65c;
  }
}

.menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: transparent;
  color: #e2b65c;
}
@media (min-width: 768px) {
  .menu-button {
    display: none;
  }
}

.menu-button__icon--collapse {
  display: block;
}
.main-header--collapsed .menu-button__icon--collapse {
  display: none;
}
.menu-button__icon--expand {
  display: none;
}
.main-header--collapsed .menu-button__icon--expand {
  display: block;
}

.main-header {
  background-color: #111111;
  color: #ffffff;
  padding: 16px 0;
}
@media (min-width: 768px) {
  .main-header {
    padding: 24px 0;
  }
}
@media (min-width: 1200px) {
  .main-header {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}

.main-header__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .main-header__container {
    flex-wrap: nowrap;
  }
}

.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}

.pagination__item {
  display: flex;
}
.pagination__item:nth-last-child(2) {
  transform: scaleX(-1);
}

.pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 1px 1px 0 0;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  text-decoration: none;
  transition: 0.3s;
}
.pagination__link--current {
  font-weight: 500;
  background-color: #111111;
  color: #ffffff;
  pointer-events: none;
}
.pagination__link--disabled {
  color: #b2b2b2;
}
.pagination__item:nth-last-child(2) .pagination__link, .pagination__item:last-child .pagination__link {
  padding: 0;
}
@media (min-width: 1200px) {
  .pagination__link:hover {
    background-color: #e2b65c;
    color: #ffffff;
  }
}

.post-card {
  display: flex;
  min-width: max-content;
}

.post-card__image {
  border-radius: 4px;
}

.post-card {
  display: flex;
  min-width: max-content;
}

.post-card__image {
  border-radius: 4px;
}

.posts {
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .posts {
    min-width: max-content;
    height: calc(100vh - 48px);
    position: sticky;
    top: 24px;
    z-index: 0;
    padding-right: 6px;
  }
}
@media (min-width: 1200px) {
  .posts {
    top: 112px;
    height: calc(100vh - 136px);
  }
}

.posts__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;
}
@media (min-width: 768px) {
  .posts__list {
    flex-direction: column;
    max-height: 100%;
    padding-right: 6px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .posts__list::-webkit-scrollbar {
    width: 4px;
  }
  .posts__list::-webkit-scrollbar-thumb {
    background-color: #e2b65c;
    border-radius: 2px;
  }
}

.posts__item {
  display: flex;
  min-width: max-content;
}

.quote-card {
  margin: 0;
  background-color: #ffffff;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .quote-card {
    flex-grow: 1;
  }
}

.quote-card__link {
  text-decoration: none;
  float: right;
  border-radius: 0 8px 0 8px;
  background-color: #b2b2b2;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  padding: 4px 8px;
  margin-left: 16px;
  margin-bottom: 16px;
}

.quote-card__top {
  padding: 8px 16px;
}
@media (min-width: 768px) {
  .quote-card__top {
    padding: 16px 24px;
  }
}

.quote-card__quote {
  quotes: none;
  display: block;
  margin-bottom: 8px;
  white-space: pre-wrap;
}

.quote-card__tags {
  font-size: 14px;
  color: #747474;
  display: flex;
  flex-wrap: wrap;
  gap: 4px 16px;
}

.quote-card__tag {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 0.3s;
  display: inline-block;
}
.tags-hidden .quote-card__tag:nth-child(n+4) {
  visibility: hidden;
  display: none;
}
.quote-card__tag--current {
  font-weight: bold;
}
@media (min-width: 1200px) {
  .quote-card__tag:hover {
    color: #e2b65c;
  }
}

.quote-card__bottom {
  position: relative;
  border-top: 1px solid #b2b2b2;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
@media (min-width: 768px) {
  .quote-card__bottom {
    padding: 8px 24px;
    gap: 24px;
  }
}

.quote-card__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  color: #b2b2b2;
}

.quote-card__button {
  margin-left: auto;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.quote-card__button svg {
  fill: none;
}
.quote-card__button--toggle-tags {
  color: #e2b65c;
}
.quote-card__button--toggle-tags::before {
  content: attr(data-hide-text);
}
.tags-hidden .quote-card__button--toggle-tags::before {
  content: attr(data-show-text);
}
.quote-card__button--favorite {
  color: #e2b65c;
}
.quote-card__button--favorite svg {
  fill: #e2b65c;
}

.quote-card__button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.quote-card__share {
  position: relative;
  z-index: 1;
  max-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.quote-card__share-links {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  right: 100%;
  background-color: #ffffff;
  display: flex;
  gap: 8px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.quote-card__share:hover .quote-card__share-links {
  visibility: visible;
  opacity: 1;
}

.quote-card__share-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #e2b65c;
  padding: 0;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.quote-card__share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  color: #b2b2b2;
}
.quote-card__share:hover .quote-card__share-button {
  color: #111111;
}

.quote-card {
  margin: 0;
  background-color: #ffffff;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .quote-card {
    flex-grow: 1;
  }
}

.quote-card__link {
  text-decoration: none;
  float: right;
  border-radius: 0 8px 0 8px;
  background-color: #b2b2b2;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  padding: 4px 8px;
  margin-left: 16px;
  margin-bottom: 16px;
}

.quote-card__top {
  padding: 8px 16px;
}
@media (min-width: 768px) {
  .quote-card__top {
    padding: 16px 24px;
  }
}

.quote-card__quote {
  quotes: none;
  display: block;
  margin-bottom: 8px;
  white-space: pre-wrap;
}

.quote-card__tags {
  font-size: 14px;
  color: #747474;
  display: flex;
  flex-wrap: wrap;
  gap: 4px 16px;
}

.quote-card__tag {
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 0.3s;
  display: inline-block;
}
.tags-hidden .quote-card__tag:nth-child(n+4) {
  visibility: hidden;
  display: none;
}
.quote-card__tag--current {
  font-weight: bold;
}
@media (min-width: 1200px) {
  .quote-card__tag:hover {
    color: #e2b65c;
  }
}

.quote-card__bottom {
  position: relative;
  border-top: 1px solid #b2b2b2;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
@media (min-width: 768px) {
  .quote-card__bottom {
    padding: 8px 24px;
    gap: 24px;
  }
}

.quote-card__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  color: #b2b2b2;
}

.quote-card__button {
  margin-left: auto;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}
.quote-card__button svg {
  fill: none;
}
.quote-card__button--toggle-tags {
  color: #e2b65c;
}
.quote-card__button--toggle-tags::before {
  content: attr(data-hide-text);
}
.tags-hidden .quote-card__button--toggle-tags::before {
  content: attr(data-show-text);
}
.quote-card__button--favorite {
  color: #e2b65c;
}
.quote-card__button--favorite svg {
  fill: #e2b65c;
}

.quote-card__button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.quote-card__share {
  position: relative;
  z-index: 1;
  max-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.quote-card__share-links {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 8px;
  right: 100%;
  background-color: #ffffff;
  display: flex;
  gap: 8px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
}
.quote-card__share:hover .quote-card__share-links {
  visibility: visible;
  opacity: 1;
}

.quote-card__share-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #e2b65c;
  padding: 0;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.quote-card__share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  padding: 0;
  color: #b2b2b2;
}
.quote-card__share:hover .quote-card__share-button {
  color: #111111;
}

.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}

.pagination__item {
  display: flex;
}
.pagination__item:nth-last-child(2) {
  transform: scaleX(-1);
}

.pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 1px 1px 0 0;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: #ffffff;
  text-decoration: none;
  transition: 0.3s;
}
.pagination__link--current {
  font-weight: 500;
  background-color: #111111;
  color: #ffffff;
  pointer-events: none;
}
.pagination__link--disabled {
  color: #b2b2b2;
}
.pagination__item:nth-last-child(2) .pagination__link, .pagination__item:last-child .pagination__link {
  padding: 0;
}
@media (min-width: 1200px) {
  .pagination__link:hover {
    background-color: #e2b65c;
    color: #ffffff;
  }
}

.quotes {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.quotes__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 768px) {
  .quotes__list {
    gap: 16px;
  }
}

.search-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  background-color: #111111;
  border: none;
  padding: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  pointer-events: all;
}
.search-modal-shown .search-modal {
  visibility: visible;
  opacity: 1;
}
@media (min-width: 768px) {
  .search-modal {
    background-color: rgba(17, 17, 17, 0.8);
  }
}
@media (min-width: 1200px) {
  .search-modal {
    padding-top: 16vh;
  }
}

.search-modal-shown {
  overflow: hidden;
  pointer-events: none;
}

.search-modal__title {
  color: #ffffff;
  padding: 16px;
}

.search-modal__form {
  background-color: #ffffff;
  flex-grow: 1;
  border-radius: 8px 8px 0 0;
  width: 100%;
  max-width: 560px;
  padding: 16px 24px;
}
@media (min-width: 1200px) {
  .search-modal__form {
    flex-grow: 0;
    border-radius: 8px;
  }
}

.search-modal__input {
  display: flex;
  width: 100%;
  border: none;
  padding: 0;
  color: #111111;
  font-size: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #b2b2b2;
  border-radius: 0;
  background-color: transparent;
}
.search-modal__input::placeholder {
  color: #b2b2b2;
}
.search-modal__input:focus {
  outline: none;
  border-bottom: 1px solid #e2b65c;
}

.search-modal__results {
  overflow-y: scroll;
}
.search-modal__results::-webkit-scrollbar {
  width: 8px;
}
.search-modal__results::-webkit-scrollbar-thumb {
  background-color: #e2b65c;
}
@media (min-width: 1200px) {
  .search-modal__results {
    max-height: 400px;
  }
}
.search-modal__results ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 16px;
}
.search-modal__results ul li {
  margin-bottom: 8px;
  color: #747474;
}
.search-modal__results ul li a {
  text-decoration: none;
  padding: 4px 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.search-modal__close {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
  background-color: transparent;
  color: #e2b65c;
  cursor: pointer;
}

.tags {
  background-color: #ffffff;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .tags {
    min-width: 240px;
    width: 240px;
    height: calc(100vh - 48px);
    position: sticky;
    top: 24px;
    z-index: 0;
  }
}
@media (min-width: 1200px) {
  .tags {
    top: 112px;
    height: calc(100vh - 136px);
  }
}

.tags__title {
  padding: 8px 16px;
  padding-bottom: 4px;
}
.tags--hidden .tags__title {
  padding: 8px 16px;
}
@media (min-width: 768px) {
  .tags__title {
    border-bottom: 1px solid #b2b2b2;
    padding-top: 16px;
    margin-bottom: 8px;
  }
}

.tags__button {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 500;
}
@media (min-width: 768px) {
  .tags__button {
    pointer-events: none;
  }
}

.tags__button-icon {
  transform: rotate(90deg) scaleX(-1);
  transition: 0.3s;
}
.tags--hidden .tags__button-icon {
  transform: rotate(90deg);
}
@media (min-width: 768px) {
  .tags__button-icon {
    display: none;
    visibility: hidden;
  }
}

.tags__list {
  list-style: none;
  margin: 0;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-top: 1px solid #b2b2b2;
}
.tags--hidden .tags__list {
  visibility: hidden;
  display: none;
}
@media (min-width: 768px) {
  .tags__list {
    flex-direction: column;
    max-height: calc(100% - 70px);
    padding-right: 6px;
    overflow-x: hidden;
    overflow-y: scroll;
    border-top: none;
    padding: 16px;
    padding-top: 0;
    margin-right: 6px;
  }
  .tags__list::-webkit-scrollbar {
    width: 4px;
  }
  .tags__list::-webkit-scrollbar-thumb {
    background-color: #e2b65c;
    border-radius: 2px;
  }
}

.tags__link {
  text-decoration: none;
  display: flex;
  gap: 8px;
  transition: 0.3s;
  color: #747474;
}
.tags__link--current {
  color: #e2b65c;
}
@media (min-width: 1200px) {
  .tags__link:hover {
    color: #e2b65c;
  }
}

.author-page__author {
  max-width: 419px;
  margin-bottom: 16px;
}
@media (min-width: 1200px) {
  .author-page__author {
    float: right;
    margin-left: 80px;
    margin-bottom: 24px;
  }
}

.author-page__image {
  width: 419px;
  height: auto;
  object-fit: contain;
  border-radius: 4px;
  display: block;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .author-page__image {
    border-radius: 8px;
  }
}
@media (min-width: 1200px) {
  .author-page__image {
    margin-bottom: 16px;
  }
}

.author-page__title {
  font-weight: 700;
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 16px;
}
.author-page__title::first-line {
  color: #e2b65c;
  font-weight: 400;
  font-size: 24px;
}
@media (min-width: 768px) {
  .author-page__title {
    font-size: 48px;
  }
}

.author-page__info-item {
  margin: 0;
  padding: 16px 0;
  border-top: 1px solid #b2b2b2;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #747474;
}

.home-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .home-page {
    flex-direction: row-reverse;
  }
}

.quote-selected {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .quote-selected {
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}

.tag-selected {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .tag-selected {
    flex-direction: row;
    align-items: flex-start;
  }
}

.tags-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .tags-page {
    flex-direction: row-reverse;
  }
}

.tags-page__right {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
}
@media (min-width: 768px) {
  .tags-page__right {
    flex-grow: 1;
    padding: 16px 24px;
  }
}

.tags-page__title {
  padding-bottom: 4px;
  border-bottom: 1px solid #747474;
}

.tags-page__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
}
@media (min-width: 768px) {
  .tags-page__list {
    column-count: 2;
    column-gap: 16px;
    padding-top: 16px;
  }
}
@media (min-width: 1200px) {
  .tags-page__list {
    column-count: 4;
  }
}

.tags-page__list-item {
  margin-bottom: 4px;
}

.tags-page__list-link {
  text-decoration: none;
  transition: 0.3s;
}
@media (min-width: 1200px) {
  .tags-page__list-link:hover {
    color: #e2b65c;
  }
}

.search-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .search-page {
    flex-direction: row-reverse;
  }
}

.search-page__right {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 768px) {
  .search-page__right {
    flex-grow: 1;
  }
}

.search-page__title {
  padding-bottom: 4px;
  border-bottom: 1px solid #747474;
}

.search-page__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 8px;
}
@media (min-width: 768px) {
  .search-page__list {
    column-count: 2;
    column-gap: 16px;
    padding-top: 16px;
  }
}
@media (min-width: 1200px) {
  .search-page__list {
    column-count: 4;
  }
}

.search-page__list-item {
  margin-bottom: 4px;
}

.search-page__list-link {
  text-decoration: none;
  transition: 0.3s;
}
@media (min-width: 1200px) {
  .search-page__list-link:hover {
    color: #e2b65c;
  }
}

.search-page__form {
  display: flex;
  margin-bottom: 16px;
}

.search-page__field {
  display: flex;
  flex-grow: 1;
  min-width: 0;
  padding: 6px 12px;
  border: 1px solid rgb(209, 213, 219);
  border-right: none;
  font-size: 16px;
  border-radius: 4px 0 0 4px;
}
.search-page__field::placeholder {
  font-size: 16px;
}
.search-page__field:focus {
  outline-color: #e2b65c;
}

.search-page__submit {
  border-radius: 0 4px 4px 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}

.alert-warning {
  color: #856a00;
  background-color: #fff5cc;
  padding: 12px 24px;
  margin: 0;
  border-radius: 4px;
  border: 2px solid #fff1b8;
}
.alert-warning figcaption {
  font-size: 1.25rem;
  font-weight: 600;
}
.alert-warning p {
  font-weight: 400;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.field[data-error]::after {
  content: attr(data-error);
  font-size: 14px;
  color: #DC0000;
}
.field > span {
  font-size: 14px;
  color: #DC0000;
}

.field__label {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.field__label span {
  color: #B2B2B2;
  font-size: 16px;
  line-height: 20px;
}

.field__input {
  border-radius: 8px;
  border: 1px solid #B2B2B2;
  background-color: #FFF;
  line-height: 1;
  font-size: 16px;
  padding: 4px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 0;
}
.field[data-error] .field__input {
  border: 1px solid #DC0000;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 768px) {
  .form {
    gap: 16px;
  }
}

.form__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0 16px;
}
.form__links a {
  max-width: max-content;
}

.form__aware {
  font-size: 14px;
  margin: 0;
}

.form__submit {
  margin-top: 8px;
  max-width: none;
}
@media (min-width: 768px) {
  .form__submit {
    margin-top: 16px;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: max-content;
  z-index: 9;
  display: flex;
  background-color: rgba(0, 0, 0, 0.64);
  opacity: 1;
  visibility: visible;
  transition: 0.3s;
  padding: 5vw;
  overflow-y: scroll;
}
.modal::-webkit-scrollbar {
  width: 0;
}
.modal::-webkit-scrollbar-track {
  background-color: #e2b65c;
}
.modal::-webkit-scrollbar-thumb {
  background-color: #111111;
}
.modal--hidden {
  opacity: 0;
  visibility: hidden;
}

.modal__container {
  position: relative;
  width: 100%;
  z-index: 0;
  max-width: 480px;
  height: max-content;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 32px;
  border-radius: 8px;
  margin: auto;
}
.modal__container--wide {
  height: 80vh;
  max-width: 880px;
}
@media (min-width: 768px) {
  .modal__container {
    padding: 80px;
  }
}

.modal__scroll {
  overflow-y: scroll;
  padding-right: 16px;
}
.modal__scroll::-webkit-scrollbar {
  width: 8px;
}
.modal__scroll::-webkit-scrollbar-track {
  background-color: #e2b65c;
}
.modal__scroll::-webkit-scrollbar-thumb {
  background-color: #111111;
}

.modal__title {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .modal__title {
    margin-bottom: 24px;
  }
}

.modal__text {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .modal__text {
    margin-bottom: 24px;
  }
}

.modal__close {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  color: #e2b65c;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.modal__close > * {
  pointer-events: none;
}

.modal__button {
  max-width: none;
}

.modal__back {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  max-width: max-content;
  gap: 8px;
  font-weight: 500;
  margin-top: 16px;
}

.profile {
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 768px) {
  .profile {
    position: relative;
  }
}

.profile__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: max-content;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  visibility: visible;
  transition: 0.3s;
  background-color: #fff;
  color: #000000;
  padding: 32px;
  padding-top: 40px;
}
.profile__modal--hidden {
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 768px) {
  .profile__modal {
    width: max-content;
    height: max-content;
    border-radius: 8px;
    position: absolute;
    top: 80px;
    left: auto;
    right: 0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    min-width: 320px;
  }
}

.profile__avatar {
  position: relative;
  margin-bottom: 16px;
}

.profile__image {
  display: block;
  object-fit: cover;
  border-radius: 8px;
}

.profile__avatar-change {
  position: absolute;
  top: -16px;
  right: -16px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #D9D9D9;
  border-radius: 50%;
  cursor: pointer;
}

.profile__name {
  padding: 0 16px 8px 16px;
  border-bottom: 1px solid #111111;
  margin-bottom: 32px;
}

.profile__navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
}

.profile__navigation-link {
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  transition: 0.3s;
}
.profile__navigation-link:hover {
  opacity: 0.7;
}
.profile__navigation-link span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
}

.profile__logout {
  max-width: none;
  width: 100%;
}

.profile__close {
  background: transparent;
  border: none;
  position: absolute;
  top: 16px;
  right: 16px;
  color: #e2b65c;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .profile__close {
    display: none;
  }
}

.profile-section__title {
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .profile-section__title {
    margin-bottom: 16px;
  }
}

.profile-section__form {
  max-width: 440px;
}

.nested-favorites {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 768px) {
  .nested-favorites {
    gap: 16px;
  }
}

.nested-favorites__item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.nested-favorites__item ol {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.nested-favorites__item--collapsed ol {
  display: none;
}
@media (min-width: 768px) {
  .nested-favorites__item {
    gap: 16px;
  }
  .nested-favorites__item ol {
    gap: 16px;
  }
}

.nested-favorites__draggable {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #ffffff;
  line-height: 1;
}
.nested-favorites__draggable:hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.24);
}
@media (min-width: 768px) {
  .nested-favorites__draggable {
    padding: 8px 24px;
  }
}

.nested-favorites__input {
  padding: 0;
  border: none;
  min-width: 0;
  display: flex;
  flex-grow: 1;
}
.nested-favorites__input:read-only {
  pointer-events: none;
}
.nested-favorites__input:focus {
  padding: 4px 8px;
}

.nested-favorites__dropdown {
  margin-left: auto;
  position: relative;
}

.nested-favorites__dropdown-button {
  border: none;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e2b65c;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s;
}

.nested-favorites__dropdown-actions {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  opacity: 0;
  visibility: hidden;
}
.nested-favorites__dropdown:hover .nested-favorites__dropdown-actions {
  opacity: 1;
  visibility: visible;
}

.nested-favorites__dropdown-action {
  display: flex;
  padding: 8px 16px;
  background-color: transparent;
  border: none;
  min-width: max-content;
  color: #747474;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s;
  width: 100%;
}
.nested-favorites__dropdown-action:hover {
  background-color: #f6f6f6;
}

.user-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .user-page {
    flex-direction: row-reverse;
  }
}

.user-page__inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media (min-width: 768px) {
  .user-page__inner {
    flex-grow: 1;
  }
}

.users-navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
@media (min-width: 768px) {
  .users-navigation {
    gap: 16px;
  }
}

.users-navigation__item {
  display: flex;
}
.users-navigation__item--wide {
  width: 100%;
  justify-content: flex-end;
  transform: translateY(8px);
}
@media (min-width: 768px) {
  .users-navigation__item--wide {
    transform: none;
  }
}

.favorites {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 768px) {
  .favorites {
    gap: 16px;
  }
}

.favorites__item {
  display: flex;
  flex-direction: column;
}

.favorites__card {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  height: 56px;
}
.favorites__card svg {
  color: #e2b65c;
}

.favorites__new {
  display: flex;
  flex-grow: 1;
}

.favorites__link {
  display: flex;
  flex-grow: 1;
  text-decoration: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
}
.favorites__card--edit .favorites__link {
  flex-grow: 0;
}
.favorites__card--edit .favorites__link span {
  display: none;
}

.favorites__card-edit {
  display: none;
  visibility: hidden;
  flex-grow: 1;
}
.favorites__card--edit .favorites__card-edit {
  display: flex;
  visibility: visible;
}

.favorites__action-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 8px;
}

.favorites__action-button {
  position: relative;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  height: 32px;
  width: 32px;
  color: #b2b2b2;
  cursor: pointer;
}
.favorites__action-button * {
  pointer-events: none;
}
.favorites__action-button svg {
  color: inherit;
}
.favorites__action-button--success svg {
  color: #66bb6a;
}
.favorites__action-button--error svg {
  color: #f44336;
}

.favorites__action-label {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  background-color: #212121;
  color: #ffffff;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px;
  min-width: max-content;
  transition: 0.3s;
}
.favorites__action-label::after {
  content: "";
  position: absolute;
  top: calc(100% - 4px);
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: #212121;
  background: linear-gradient(135deg, transparent 50%, black 50%);
  transform: rotate(45deg) translateX(-50%);
}
.favorites__action-button:hover .favorites__action-label, .favorites__action-button:focus .favorites__action-label {
  opacity: 1;
  visibility: visible;
}

.favorite-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.favorite-list__item {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: max-content;
}
.favorite-list__item--child {
  margin-left: 32px;
}

.favorite-list__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 2px solid #111111;
  color: transparent;
}
input:checked + .favorite-list__icon {
  color: white;
  border: 1px solid #111111;
  background-color: #66bb6a;
}