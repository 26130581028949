@import "_post-card.scss";

.posts {
  padding: 16px;
  background-color: $base-white;
  border-radius: 8px;

  @include tablet {
    min-width: max-content;
    height: calc(100vh - 48px);
    position: sticky;
    top: 24px;
    z-index: 0;
    padding-right: 6px;
  }

  @include desktop {
    top: 112px;
    height: calc(100vh - 136px);
  }
}

.posts__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 8px;
  overflow-x: scroll;
  overflow-y: hidden;

  @include tablet {
    flex-direction: column;
    max-height: 100%;
    padding-right: 6px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $base-secondary;
      border-radius: 2px;
    }
  }
}

.posts__item {
  display: flex;
  min-width: max-content;
}
